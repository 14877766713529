<template>
  <div class="columns is-gapless is-centered is-mobile">
    <div class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen is-4-fullhd mb-4">
      <section class="section has-text-centered pt-5 pb-1">
        <div v-if="eventName.trim().length === 0 || stage === 0" class="is-size-2 break-word px-3 has-text-white is-unselectable">|</div>
        <div v-if="eventName.trim().length > 0 && stage === 1" class="is-size-2 break-word px-3 event-name-modifier is-unselectable">{{ eventName }}</div>
      </section>
      <section class="section">
        <div v-if="stage === 0" class="field">
          <label class="label is-unselectable">Event Name</label>
          <div class="control">
            <input
            class="input"
            type="text"
            placeholder="Sam's Birthday, Dinner at Macs, Daytrip in New York..."
            v-model="eventName"
            @input="$event.target.composing = false"
            >
          </div>
        </div>
        <div v-if="stage === 1" class="field">
          <div
          @click="back()"
          v-bind:class="{'has-text-white': loading}">
          <i class="fas fa-angle-left mr-2 mb-5 is-unselectable is-clickable"></i>
          back
          </div>
          <label class="label">Your Name</label>
          <div class="control">
            <input
            class="input"
            type="text"
            placeholder="Susan, Anthony, Anonymous001..."
            v-model="name"
            :disabled="loading"
            @input="$event.target.composing = false"
            >
          </div>
          <p class="help has-text-grey-light">You'll add more names later.</p>
        </div>
        <button
        class="button is-info is-fullwidth"
        v-bind:class="{'is-loading': loading}"
        @click="next()"
        :disabled="(eventName.length === 0 && stage === 0) || (name.trim().length === 0 && stage === 1) || loading"
        >
        {{
          stage === 0 ? 'Next' : 'Let\'s Split!'
        }}
        </button>
      </section>
      <section class=" section">
        <PreviousEvents />
      </section>
    </div>
  </div>
</template>

<script>
import { masterFunction } from '@/firebase/init'
import PreviousEvents from '@/components/PreviousEvents'

export default {
  name: 'Start',
  components: {
    PreviousEvents
  },
  data() {
    return {
      eventName: '',
      name: '',
      stage: 0,
      loading: false
    }
  },
  mounted() {
    localStorage.removeItem('BestBillSplitterEventId')
  },
  methods: {
    back() {
      if(this.stage === 1) {
        this.stage = 0
      }
    },
    next() {
      if(this.stage === 0) {
        this.stage = 1
      } else {
        this.loading = true
        masterFunction({
          'methodName': 'create-event',
          'eventName': this.eventName,
          'name': this.name.trim()
        }).then(res => {
          this.$store.commit('updateName', this.name.trim())
          this.$router.push({
            name: 'MainAdmin',
            params: {
              eventId: res.data.eventId,
              nameSlug: res.data.nameSlug,
              adminKey: res.data.adminKey
            }
          })
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
}
</script>

<style>
.event-name-modifier {
  line-height: 38px;
}
</style>
