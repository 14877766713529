<template>
  <div class="has-text-centered" v-if="previousEvents && previousEvents.length > 0">
    <label class="label mb-0">Previous events</label>
    <div class="button-container-modifier mt-0">
      <button 
      @click="goToEvent(index)"
      v-for="(previousEvent, index) in previousEvents"
      :key="index"
      class="button is-small has-text-grey-light mr-1 mt-1"
      >
        {{ shortenName(previousEvent.name) }}
      </button >
    </div>
    <div @click="deleteHistory()" class="has-text-grey-light is-size-7 is-clickable mt-3">
      delete history
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviousEvents',
  data() {
    return {
      previousEvents: []
    }
  },
  methods: {
    goToEvent(index) {
      const event = this.previousEvents[index]
      if(event.adminKey){
        this.$router.push({
          name: 'MainAdmin',
          params: {
            eventId: event.eventId,
            nameSlug: event.nameSlug,
            adminKey: event.adminKey
          }
        })
      } else {
        this.$router.push({
          name: 'Main',
          params: {
            eventId: event.eventId,
            nameSlug: event.nameSlug
          }
        })
      }
    },
    deleteHistory() {
      console.log('deleteHistory')
      localStorage.removeItem('BestBillSplitterPreviousEventsString')
      this.previousEvents = []
    },
    shortenName(name) {
      return name.length < 14 ? name : name.substring(0, 13) + '...'
    }
  },
  mounted() {
    try {
      this.previousEvents = JSON.parse(localStorage.BestBillSplitterPreviousEventsString)
    } catch(err) {
      localStorage.removeItem('BestBillSplitterPreviousEventsString')
    }
  }
}
</script>

<style>
.button-container-modifier {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
</style>